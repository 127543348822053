.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.account {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  left:10px;
  position: absolute;
  top: 10px;
  width: 40px;

}

.body-post {
  margin: 20px;
}

.body-post::first-letter {
  color: black;
  font-size: 150%;
}
.related-products {
  margin-top: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.related-products-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}

.related-product {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
}

.related-product:hover {
  transform: translateY(-3px);
}

.related-product-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}


.related-product-image {
  width: 50%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.related-product-info {
  padding: 10px;
}

.related-product-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.related-product-price {
  font-size: 0.8rem;
  color: #777;
}



.button-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.card-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.catBox {
  align-items: center;
  background-color: beige;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.comment-count{
display: flex;

margin-top: 2px;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;

background-image: url('assets/social/messenger.png');

}
.comment-number{
  color:black;
  font-size: 18px;
  font-weight:bolder;
  text-align: center;
  margin-bottom: 6px;
}
.comment-bottom {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 10px;
}
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}
.links-cat{
  display: flex;
  justify-content: center;

 border-radius: 10px;

}

.cat-link{
  display: flex;
  gap: 60px;
  
  justify-content: center;
  border-radius: 8px;
  width: 300px;
}
.input {
 
}
.latest-reviews{
  display: flex;
  gap: 10px;

}
.product-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: center; 
  margin-bottom: 40px;
}

.product {
  max-width: 300px;
 max-height: 500px;
 margin-bottom: 20px;
}



.reviewCounter {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 620px;
  transform: translateY(-50%);
  cursor: pointer;
  /* Anpassa andra stilattribut efter behov, t.ex. färg, storlek */
}
.cat-category{
 display: flex; 
 flex-direction: column;

  align-items: center;
 justify-content: center;
 
}
.category{
  display: flex;
  flex-direction: row;
}
.image-container {
  margin-top: 20px;
  display: flex;
  
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
  border-style: solid;
  border-color: #ccc;
}
.comment-bottom-fullpage {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 10px;
}
.has-background-grey-lighter {
  background-color: #f4f4f4;
  
}
.comment-comment {
  display: flex;
  margin-left: 25px;
  margin-bottom: 10px;
}

.comment-icons {
  margin-top: 10px;
}

.comment-mail-and-name {
  font-weight: bold;

display: flex;
gap: 20px;

}
.comment-mail-and-name img{
  border-radius: 100%;
width:50px;
height:50px;
}
.comment-mail-and-name a {
  color: black;
}

.comment-text {
  padding-top: 2px;
}
.container-post-ka{
display: flex;
flex-direction: row;
margin-top: 10px;

}
.related-blogs {
  margin-top: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.related-blogs-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.related-blogs {
  margin-top: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.related-blogs-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}

.related-blog {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
}

.related-blog:hover {
  transform: translateY(-3px);
}

.related-blog-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.related-blog-image {
  width: 25%;
  height: auto;
  padding: 5px;
  object-fit: cover;
  border-radius: 10px;
}

.related-blog-info {
  padding: 10px;
}

.related-blog-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.related-blog-category {
  font-size: 0.8rem;
  color: #777;
}
.container-post {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}
.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.confirmation-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.confirmation-modal p {
  font-size: 16px;
  margin-bottom: 20px;
}

.confirmation-modal .modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.confirmation-modal button {
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.confirmation-modal button:hover {
  background-color: #0056b3;
}
.new-order {
  background-color: #4caf50; /* Grön bakgrundsfärg */
  color: white; /* Vit textfärg */
  padding: 4px 8px; /* Lite padding runt texten */
  border-radius: 4px; /* Rundade hörn */
}
.box {
  padding: 20px;
  background-color: #f5f5f5;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-container img {
  cursor: pointer;
  margin-left: 10px;
}

.input {
  padding: 5px;
  width: 200px;
  margin-right: 10px;
}

.close-icon {
  cursor: pointer;
  margin-left: 10px;
}

.table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.subtitle {
  font-size: 16px;
}

.subtitle.is-5 {
  font-size: 20px;
}

.link {
  color: blue;
  text-decoration: underline;
}

.link:hover {
  color: #0044cc;
}
.rating-stars {
  display: flex;
  align-items: flex-end; /* Align stars to the bottom */
  justify-content: center;
  margin-top: 5px; /* Adjust this value to move stars higher */
}
.rating-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  vertical-align: middle;
}
.content-1 {
  margin: auto;
  padding: 10px;
  width: 100%;
}

.counter-like {
  font-size: 25px;
  font-weight: bold;
}

.del {
  color: red;
}
.help {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.is-danger {
  border-color: red;
}
.forgot-password-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.edit {
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  width: 40px;

  
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -20px;
}
.footer-links{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 10px
}
.login-button{
display: flex;
justify-content: center;
margin-top: 10px;
}
.huvud {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.right-aligned {
  display: inline-block;
  float: right;
}

.review-count {
  background-color: #f4f4f4;
  color: black;
  border: 1px solid #ccc;
  padding: 5px 20px;
  border-radius: 10px;
}
.headComment{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.img-front {
  border-radius: 10px;
  border-color: #ccc;
  border-style: solid;
  background-color: #f4f4f4;
}
.img-front-full-products{
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-self: center;

  background-color: #f4f4f4;
}

.img-front-full-post{
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-self: center;
  border-style: solid;
  border-color: #ccc;
  background-color: #f4f4f4;
}
.img-front-full {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  border-color: #ccc;
  border-style: solid;
  background-color: #f4f4f4;
}

.img-front-full img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.kategori {
  font-size: 20px;
  text-align: center;
}

.like-count {
  font-size: 19px;
  font-weight: bold;
}

.likunlike {
  font-size: 18px;
  font-weight: bold;
}

.lankar {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.page-numbering{
display: flex;
justify-content: center;
font-size: larger;
font-weight: bold;
}
.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.padding-post {
  padding-bottom: 20px;
}

.panel-text {
  color: black;
  font-size: large;
  font-weight: bold;
  position: relative; /* För att positionera ::after-pseudoelementet */
}

.panel-text::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px; /* Placera linjen precis under länken */
  left: 0;
  width: 100%; /* Linjen ska sträcka sig över hela länkens bredd */
  height: 2px; /* Linjens tjocklek */
  background-color: black; /* Färg på linjen */
  transform: scaleX(0); /* Initialt är linjen osynlig (0-skala) */
  transition: transform 0.2s ease-in-out; /* Animeringseffekt för att visa linjen */
}

.panel-text:hover::after {
  transform: scaleX(1); /* När länken hovras över ändras skalan till 1 för att visa linjen */
}

.panel-text-button {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.panel-text-drop {
  display: flex;
  color: #1684ac;
  font-size: large;
  font-weight: bold;
  justify-content: center;
}
.tag-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;

}
  .tag-container-front {
    display: grid;

    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }
.review-img {
  position: relative;
  display: inline-block;
}

.review-img h2.image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 8px;
  margin: 0;
  font-weight: bold;
  text-align: center;
  display: none;
}

.review-img:hover h2.image-title {
  display: block;
}

.rec-img {
  position: relative;
  display: inline-block;
}

.rec-img h2.image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: rgba(22, 132, 172, 0.8);
  border-radius: 8px;
  padding: 8px;
  margin: 0;
  font-weight: bold;
  text-align: center;
  display: none;
}

.rec-img:hover h2.image-title {
  display: block;
  color: white;
}
.panel-text-drop a:hover {
  color: black;
  font-size: large;
  font-weight: bold;
  justify-content: center;
}
.post-top {
  display: flex;
  display: row;
  gap: 50%;
  margin-top: 5px;
}

.post-top-all {
  display: flex;
  flex-direction: row;
  gap: 75%;
  margin-left: 20px;
}

.profil {
  align-self: center;
  display: flex;
  justify-content: center;
}

.profil-fulpage {
  border-radius: 10%;
  height: 400px;
  width: 400px;
  border:solid 1px;
  border-color:#ccc;
  background-color: white;
  
  
}

.profil-img {
  align-items: center;
  display: flex;
  flex-direction: column;
  
}

.profil-info {
  align-items: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.side-missing {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 42px;
}

.size-input {
  padding: 10px;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center; /* Centrera vertikalt */
  justify-content: center; /* Centrera horisontellt */
  gap: 10%;
  padding: 4px;
  border-radius: 10px; /* Kombinerade border-radius för alla hörn */
  border: 2px solid #ccc;
  background-color: #fff;
  
  position: relative;
}

.social::after {
  content: "Dela";
  position: absolute;
  top: -32px; /* Justera vertikalt läge */
  left: 50%; /* Centrera horisontellt */
  transform: translateX(-50%);
  background-color:white ;
  width: 100px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-color: #ccc;
  border: 2px solid #ccc;
  border-bottom: none; 
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.socialIcons {
  cursor: pointer;
  margin-top: 5px;
  width: 40px;
}

.socialIcons-front {
  cursor: pointer;
  margin-top: 2px;
  width: 40px;
}

.sort-this {
  /* Add the class name "sort-this" to the parent container to apply alphabetical sorting */
}

.space {
  margin-top: 10px;
}

.space-drone {
  margin-left: 10px;
}

.space-img {
  margin-top: 10px;
}

.space-img button {
  display: flex;
  margin-top: 10px;
}

.space-profil {
  padding: 10px;
  text-align: center;
}



.text-center {
  /* Add the class name "text-center" to center align the text */
}

.top-page-search {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.user-img {
  border-radius: 50%;
  width: 10%;
}
.logout-modal{
  display: flex;
align-items: center;
justify-content: center;
flex-direction: row;

}
.account{

}
.loggCounter{
  display: flex;

margin-top: 2px;
justify-content: center;
align-items: center;
width: 150px;
height: 20px;
border-radius: 5px;
background-color:#1684ac;
color:white;
font-size: 15px;
font-weight: bold;
margin-left: 20px;
}
.filter-container{
  display: flex;
 gap: 10px;
 
  }
  .filter-container label {
    display: none;
    }
.logout-content{
max-width: 500px;
  border-radius: 5px; 
  padding: 20px 30px;
  border-color: black;
  border-style: solid;
  background-color:  #1684ac;
  color: white;
  position:fixed;

  top: 45%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%,9999990px);
  width: 95%;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}
.modal-buttons{
  display: flex;
  flex-direction: row;
  gap:10px;
  margin-top: 10px;
  justify-content: center;

}
@media only screen and (max-width: 750px) {
  .account {
    align-items: center;
    display: flex;
    height: 45px;
    justify-content: center;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 45px;
  }
  
  
  .column.is-full-mobile,
  .column.is-half-desktop,
  .column.is-half-tablet {
    width: 100%;
  }

  .comment-bottom {
    align-items: center;
    display: flex;
  }

  .comment-bottom-fullpage {
    align-items: center;
    display: flex;
    margin-top: 10px;
  }

  .columns.is-multiline {
    flex-direction: column;
  }

  .custom-card {
    padding: 2%;
  }
  .tag-container-front {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .tag-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .custom-card table {
    width: 100%;
  }
  
  .custom-card table td {
    display: block;
  }
  .container-post{
display: flex;
flex-direction: column;
align-items: center;
  }
  .custom-card table td:first-child {
    margin-bottom: 5px;
  }

  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }

  .post-top {
    display: flex;
    flex-direction: row;
    gap: 45%;
  }
.filter-container{
display: flex;
flex-direction: column;
margin-bottom: 10px;
}
.filter-container label {
display: none;
}
  .post-top-all {
    gap: 30%;
    display: flex;
    flex-direction:row;
  }
  .profil-fulpage {
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }
  
  .profil-img {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .role-thumbnail img {
    height: auto;
    max-width: 40%;
  }

  .subtitle.is-5,
  .button {
    font-size: 14px;
  }
  

  .table-profil {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 22px;
  }

  .title-mobil {
    margin-bottom: 5px;
  }

  .top-page-search {
    display: flex;
    flex-direction: column;
    gap: 15%;
    margin-bottom: 10px;
  }
  .latest-reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .latest-reviews > div {
    width: 30%; /* Adjust the width as needed */
    margin-bottom: 20px; /* Adjust the margin as needed */
  }
  .reviewCounter {
    position: absolute;
    top: 82px;
    right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-weight: bold;
  }
  .related-blogs {
    margin-top: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  
  .related-blogs-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .related-blogs {
    margin-top: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .related-blogs-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .related-blog {
    flex: 1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
  }
  
  .related-blog:hover {
    transform: translateY(-3px);
  }
  
  .related-blog-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
  }
  
  .related-blog-image {
    width: 30%;
    height: auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .related-blog-info {
    padding: 10px;
  }
  
  .related-blog-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .related-blog-category {
    font-size: 0.8rem;
    color: #777;
  }
  
}

