.share-buttons {
    display: flex;
    gap: 40px;
    
}
  
  .share-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    
  }
  
  .share-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
   width: 120px;
    display: none;
    text-align: center;
    background-color: #f4f4f4;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .share-icon:hover .share-tooltip {
    display: block;
  }
  .share-icon img {
    height: 40px; /* Justera höjden efter behov */
  }