.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .red-button {
    color: red;
  }
  .updown{
   width:20px;
  }
  .closebutton{
    width: 20px;
  }
  .post{
display: flex;
flex-direction: row;

  }
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .centered-content {
    text-align: center;
  }
  
 
  

  
  .foreground-image {
    position: relative;
    z-index: 2; /* Lägg till denna för att placera den främre bilden överst */
  }
 
  .update-icon {
    margin-left: 10px; /* Adjust spacing as needed */
  }
  .field{
    margin-bottom: 10px;
  }

  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    border-color: Black;
    border-style: solid;
  }
  .delivery-info {
    margin-bottom: 20px;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .label {
    font-weight: bold;
  }
  
  .input {
    border-color: #dbdbdb;
  }
  
  .input.is-focused {
    border-color: #3273dc;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
  }
  
  .submit-button {
    margin-top: 10px;
  }
  .processing-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 5px;
    animation: fade-in 0.5s ease, fade-out 0.5s ease 2.5s forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
  @media only screen and (max-width: 750px) {
    .modal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
    .modal-content {
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      width: 350px;
      border-color: Black;
      border-style: solid;
    }
   .close{
    width: 15px;
    
   }
   .post {
    display: flex;
    flex-direction: column;
  }

  .field {
    margin-bottom: 10px;
  }
  .input{
    width: 500px;
  }
}
  