.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #ccc; /* Bakgrundsfärg för hela progressbaren */
    position: relative;
    margin-top: 10px; /* Justera detta efter ditt designbehov */
  }
  
  .progress {
    height: 100%;
    width: 0;
    background-color: #007bff; /* Färg för fyllningen av progressbaren */
    transition: width 0.3s ease-in-out; /* Animerad övergång för fyllningen */
    text-align: center; /* Centrera texten i progressbaren */
    color: #fff; /* Textfärg */
    font-weight: bold;
    line-height: 20px; /* Vertikal centrerad text */
    overflow: hidden; /* Döljer texten om den är bredare än fyllningen */
  }

  
  
  