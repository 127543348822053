.message-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 10vh; /* Hela visningshöjden */
  text-align: center;

}

.message-container h1 {
  font-size: 3rem; /* Justera storleken efter behov */
}
@media screen and (max-width: 1300px) {
  .message-container {
    height: 15vh; /* Anpassa höjden för mindre skärmar */
  }

  .message-container h1 {
    font-size: 1.5rem; /* Anpassa storleken för mindre skärmar */
  }
}
@media screen and (max-width: 1080px) {
  .message-container {
    height: 10vh; /* Anpassa höjden för mindre skärmar */
  }

  .message-container h1 {
    font-size: 1.5rem; /* Anpassa storleken för mindre skärmar */
  }
}
@media screen and (max-width: 768px) {
  .message-container {
    height: 20vh; /* Anpassa höjden för mindre skärmar */
  }

  .message-container h1 {
    font-size: 1.5rem; /* Anpassa storleken för mindre skärmar */
  }
}