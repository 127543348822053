@media screen and (max-width: 768px) {
    .table-container {
      overflow-x: auto;
      display: block; /* Ändra till block-element */
    }
    
    .table {
      width: 100%;
    }
    
    .table thead {
      display: none; /* Dölj tabellhuvudet på mobilen */
    }
    
    .table tbody tr {
      margin-bottom: 1rem;
      text-align: center;
      display: block; /* Ändra till block-element */
      border: 1px solid #ccc;
      padding: 1rem;
    }
    
    .table tbody td {
      width: 100%;
      display: block; /* Ändra till block-element */
      text-align: center;
    }
    
    .table tbody td:last-child {
      text-align: right; /* Justera sista cellen till höger i sin kolumn */
    }
    
    .table tbody td:first-child {
      font-weight: bold;
    }
  }