.weather-container {
    display: flex;
    align-items: center;
  }
  
  .weather-info {
    display: flex;
    align-items: center;
  }
  
  .weather-info img {
    max-width: 80px; /* Anpassa storleken efter dina ikoner */
    margin-right: 10px; /* Justera avståndet mellan ikonen och texten */
  }
  
  .weather-text {
    display: flex;
    flex-direction: column;
  }
  
  /* Mediaförfrågan för mobil (max bredd 768px) */
  @media (max-width: 768px) {
    .weather-container {
      flex-direction: column; /* Ändra riktning till kolumn för mobilvisning */
      text-align: right; 
      margin-top: -10px;
      

    }
    .weather-text {
        
        display: none;
      
      }
  
    .weather-info img {
        max-width: 50px;
        margin-top: 15px; 
         }
  }
 
