
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: white;
}
.navbar-dropdown {
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
  position: absolute;
  min-width: 160px;
}
.navbar-item {
background-color: white;

}
.navbar-item:hover .navbar-dropdown {
  display: block;
  background-color: white;
}
.logout-container {
  position: relative;
}

.logout-container:hover::before {
  content: "Logga ut";
  position: absolute;
  bottom: -26px; /* Justera avståndet här för önskat utseende */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  
}


.navbar-dropdown a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.navbar-dropdown a:hover {
  background-color: #ddd;
}

/* Styling for the "Profil meny" dropdown */
.navbar-dropdown.profile-dropdown {
  right: 0;
  left: auto;
}
.logo-my {
margin-bottom: -20px;
}

.logo-my img {
  width: 180px;
  margin-left: 40px;
}

.navbar-menu {
color:black
}
.navbar-link {
  cursor: pointer;
}

/* Stil för menylänken när den är aktiv */
.navbar-link.active {
  background-color: #f0f0f0; /* Ange önskad bakgrundsfärg */
}

/* Stil för menyalternativ */
.navbar-dropdown a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

/* Stil för menyalternativ vid hovring */
.navbar-dropdown a:hover {
  background-color: #ddd; /* Ange önskad bakgrundsfärg vid hovring */
}
.navbar-item {
background-color: white;
}
.cart-icon-container {
  float: right;
  position: relative;
  display: flex; /* To align the badge correctly */
  align-items: center;
  top: -50px;
  left: -70px;
  width: 40px; /* To center the badge vertically */
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  
  background-color: #f4f4f4;
  color: black;
  border: 1px solid #ccc; 
 
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1080px) {
 

  .cart-icon-container {
    float: right;
    position: relative;
    display: flex; /* To align the badge correctly */
    align-items: center;
    bottom: 10%;
    right:50%;
    width: 40px; /* To center the badge vertically */
  }
  .navbar-item.has-dropdown:hover .navbar-dropdown {
    display: block;
  }
  .navbar-item.has-dropdown .navbar-dropdown {
    display: none; /* Dölj dropdown-menyen som standard */
  }
  
  .navbar-item.has-dropdown:hover .navbar-dropdown {
    display: block; /* Visa dropdown-menyen när du hovrar över .navbar-item.has-dropdown */
  }
  .cart-badge {
    position: absolute;
    top: -10px;
    left: 25px;
    background-color: #f4f4f4;
    color: black;
    border: 1px solid #ccc; /* 1 pixel tjock solid kantlinje */
    border-radius: 50%;
    width: 25px;margin-bottom: -20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

/* Add your existing styles for mobile view */
@media (max-width: 750px) {
  .navbar {
    flex-direction: column;
  }
.logo-my{
  margin-bottom: -40px;
}
  .logo-my img{
      width: 120px;
margin-right: 150px;


  }

  .navbar-menu {

    position: absolute;
    top: 80%;
    right: 5%;
    background-color: white;
    border-radius: 5px;
    border-color: black;
    border-style: solid;
    width: 250px;

  }

  .navbar-end {
    /* Add styles for menu items in mobile view */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-item {
    /* Add styles for each menu item in mobile view */
    padding: 10px;
  }
  .navbar-item.has-dropdown:hover .navbar-dropdown {
    display: block;
  }
  .navbar-item.has-dropdown .navbar-dropdown {
    display: none; /* Dölj dropdown-menyen som standard */
  }
  
  .navbar-item.has-dropdown:hover .navbar-dropdown {
    display: block; /* Visa dropdown-menyen när du hovrar över .navbar-item.has-dropdown */
  }
  .navbar-burger {
    /* Add styles for the burger menu icon */
    display: block;
    margin-top: 15px;
    margin-left: 0px;
    cursor: pointer;
  }
  .cart-icon-container {
    float: right;
    position: relative;
    display: flex; /* To align the badge correctly */
    align-items: center;
    top: -60px;
    left: 50px;
    width: 40px; /* To center the badge vertically */
  }
  
  .cart-badge {
    position: absolute;
    top: 8px;
    right: -35px;
    background-color: white;
    color: black;
   
    border-color:black;
    border-style: solid;
    border-radius: 30%;
    width: 35px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
