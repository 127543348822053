.order-confirmation {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .confirmation-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .confirmation-message {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .confirmation-list {
    list-style: none;
    padding: 0;
  }
  
  .order-item {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
  
  .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .product-list {
    list-style: none;
    padding: 0;
  }
  
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
  }
  
  .product-title {
    font-weight: bold;
  }
  
  .product-price {
    font-weight: bold;
  }
  
  .order-details {
    margin-top: 15px;
  }
  
  .order-total {
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    margin-top: 20px;
  }